import React from 'react';
import { Button } from 'primereact/button';

const Pricing = () => {
    const plans = [
        { name: 'Free', price: '$0', features: ['Feature A', 'Feature B'] },
        { name: 'Pro', price: '$19.99', features: ['Feature C', 'Feature D'] },
        { name: 'Enterprise', price: '$49.99', features: ['Feature E', 'Feature F'] }
    ];

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 p-8">
            <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-4xl font-bold mb-8">Pricing Plans</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {plans.map((plan) => (
                        <div key={plan.name} className="bg-white p-8 rounded-lg shadow-lg">
                            <h3 className="text-2xl font-bold mb-4">{plan.name}</h3>
                            <p className="text-4xl font-bold text-blue-600 mb-6">{plan.price}</p>
                            <ul className="text-gray-600 mb-8">
                                {plan.features.map((feature, index) => (
                                    <li key={index}>{feature}</li>
                                ))}
                            </ul>
                            <Button label="Select Plan" className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-all" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Pricing;
