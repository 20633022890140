import React from 'react';

const DropdownMenu = ({ items, width, styles = {} }) => (
  <ul style={{ width, ...styles, top: '24px' }} className="absolute bg-white border border-gray-200 rounded-lg shadow-lg mt-2 z-50 p-2">
    {items.map((item, index) => (
      <li key={index} className="flex items-center space-x-2 px-4 py-2 hover:bg-gray-100 transition-all cursor-pointer">
        {item.icon && <i className={`${item.icon} text-lg`} />} {/* PrimeIcons */}
        <a href={item.href} className="hover:text-blue-600">{item.label}</a>
      </li>
    ))}
  </ul>
);

export default DropdownMenu;
