// Tooltip.js
import React from 'react';

const Tooltip = ({ content, isCollapsed }) => {
    return (
        <div
            className={`absolute left-full ml-2 p-2 whitespace-no-wrap bg-gray-900 text-white text-sm rounded shadow-lg transition-all duration-200 opacity-0 group-hover:opacity-100 pointer-events-none
            ${isCollapsed ? 'top-0' : ''}`}
            style={{ transform: 'translateY(-50%)', top: '50%' }}
        >
            <div className="absolute left-[-8px] top-[50%] transform -translate-y-1/2 h-0 w-0 border-t-[8px] border-t-transparent border-b-[8px] border-b-transparent border-r-[8px] border-r-gray-900"></div>
            {content}
        </div>
    );
};

export default Tooltip;
