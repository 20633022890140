import React from 'react';
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';
import Dropzone from '../components/Dropzone';
import { Button } from 'primereact/button';
import DropdownComponent from '../components/DropdownComponent';
import backgroundImage from '../assets/background-image.jpeg';

const audioFormats = [
  { format: '8SVX', description: 'Amiga 8SVX', link: '8SVX Converter' },
  { format: 'AAC', description: 'Advanced Audio Coding', link: 'AAC Converter' },
  { format: 'AC3', description: 'AC3 Audio File', link: 'AC3 Converter' },
  { format: 'AIFF', description: 'Audio Interchange File Format', link: 'AIFF Converter' },
  { format: 'AMB', description: 'Ambisonic B-Format', link: 'AMB Converter' },
  { format: 'AMR', description: 'Adaptive Multi Rate', link: 'AMR Converter' },
  { format: 'APE', description: "Monkey's Audio", link: 'APE Converter' },
  { format: 'AU', description: 'Sun Microsystems AU files', link: 'AU Converter' },
];

const audioConversions = [
  ['MP3 to AAC', 'WAV to AAC', 'AIFF to AAC', 'FLAC to AAC'],
  ['AAC to MP3', 'AAC to WAV', 'AAC to AIFF', 'AAC to FLAC'],
  ['OGG to AAC', 'M4A to AAC', 'MP2 to AAC', 'M4R to AAC'],
  ['AAC to OGG', 'AAC to M4A', 'AAC to M4R', 'AAC to MP2'],
  ['MP4 to AAC', 'AC3 to AAC', 'APE to AAC', 'AIFF to AAC'],
  ['AAC to MP4', 'AAC to AC3', 'AAC to APE', 'AAC to AIFF'],
  ['AMR to AAC', 'WMA to AAC', 'AU to AAC', 'MKA to AAC'],
  ['AAC to AMR', 'AAC to WMA', 'AAC to AU', 'AAC to MKA'],
  ['SPX to AAC', '3GA to AAC', 'AMZ to AAC', 'DTS to AAC'],
  ['AAC to SPX', 'AAC to 3GA', 'AAC to AMZ', 'AAC to DTS'],
];

function AudioConverter() {
  return (
    <div className="font-sans bg-gray-50">
      <TopNav />

      {/* Hero Section */}
      <div className="relative w-full h-screen mt-[-56px]" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
        <section className="flex items-center h-full justify-center px-0 md:justify-start md:pl-20 "> {/* Adjusted alignment */}
          <Dropzone />
        </section>
      </div>

      {/* App features */}
      <section className="container mx-auto text-center py-20 max-w-[950px]">
          <h2 className="font-syne text-5xl font-bold mb-20">The Easiest Way to Convert Audio Files</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
              <div className="p-8 rounded-xl text-left">
                  <h3 className="text-2xl font-semibold mb-4">Security</h3>
                  <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div className="p-8 rounded-xl text-left">
                  <h3 className="text-2xl font-semibold mb-4">Security</h3>
                  <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div className="p-8 rounded-xl text-left">
                  <h3 className="text-2xl font-semibold mb-4">Security</h3>
                  <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div className="p-8 rounded-xl text-left">
                  <h3 className="text-2xl font-semibold mb-4 hover:text-blue-600 transition-all">Security</h3>
                  <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
          </div>
      </section>

      {/* Supported Formats Table */}
      <section className="container mx-auto py-20 max-w-[950px]">
        <h3 className="font-syne text-3xl font-bold mb-6">Supported Formats</h3>
        <table className="w-full text-left border-collapse mb-20">
          <thead>
            <tr className="border-b border-gray-300">
              <th className="py-2 px-4 font-semibold">Format</th>
              <th className="py-2 px-4 font-semibold">Description</th>
              <th className="py-2 px-4 font-semibold text-right"></th>
            </tr>
          </thead>
          <tbody>
            {audioFormats.map((format, index) => (
              <tr
                key={index}
                className="border-b border-gray-200 cursor-pointer hover:bg-gray-100 transition-all"
                onClick={() => window.location.href = '#'}
              >
                <td className="py-2 px-4">{format.format}</td>
                <td className="py-2 px-4">{format.description}</td>
                <td className="py-2 px-4 text-right hover:text-blue-600 transition-all">
                  <a href="#">
                    {format.link} <i className="pi pi-angle-right" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* All Supported Audio Conversions */}
        <h3 className="font-syne text-3xl font-bold mb-6">All Supported Audio Conversions</h3>
        <table className="w-full text-center border-collapse">
          <tbody>
            {audioConversions.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b border-gray-200">
                {row.map((conversion, colIndex) => (
                  <td key={colIndex} className="py-2 px-4">
                    <a href="#" className="hover:text-blue-600 transition-all">{conversion}</a>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* FAQ */}
      <section className="container mx-auto py-10 px-4 md:px-8 max-w-[950px]">
          <h2 className="font-syne text-3xl font-bold text-center mb-10">Have questions about converting your files?</h2>
          <div>
              <details>
                  <summary className="flex justify-between items-center font-bold border-t-[1px] border-gray-300 py-4 hover:text-blue-600 transition-all">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                      <svg className="w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                      </svg>
                  </summary>
                  <p className="text-gray-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </details>

              <details>
                  <summary className="flex justify-between items-center font-bold border-t-[1px] border-gray-300 py-4 hover:text-blue-600 transition-all">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                      <svg className="w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                      </svg>
                  </summary>
                  <p className="text-gray-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </details>

              <details>
                  <summary className="flex justify-between items-center font-bold border-t-[1px] border-gray-300 py-4 hover:text-blue-600 transition-all">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                      <svg className="w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                      </svg>
                  </summary>
                  <p className="text-gray-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </details>
          </div>
      </section>

      {/* API Advert */}
      <section className="py-10 px-4 md:px-8 mx-auto max-w-[950px]">
          <div className="container bg-white mx-auto border border-gray-300 rounded-lg p-12">
              <h2 className="font-syne text-3xl font-bold text-center mb-6">Convert files over API</h2>
              <p className="text-center mb-6 text-gray-600">Some description about API conversion...</p>
              <div className="flex justify-center">
                  <button className="bg-blue-600 text-white py-2 px-4 rounded mr-2 hover:bg-blue-700 active:bg-blue-800 transition-all">Get Pricing</button>
                  <button className="border border-blue-600 text-blue-600 py-2 px-4 rounded hover:bg-blue-50 active:bg-blue-100 transition-all">Documentation</button>
              </div>
          </div>
      </section>

      <Footer />
    </div>
  );
}

export default AudioConverter;
