// DashboardLayout.js
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/doctransit_logo.svg';
import Tooltip from '../components/Tooltip';
import 'primeicons/primeicons.css';

const DashboardLayout = ({ children }) => {
    const location = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isAccordionOpen, setAccordionOpen] = useState(false);

    const toggleSidebar = () => setIsCollapsed((prevState) => !prevState);

    const menuItems = [
        { label: 'Dashboard', icon: 'pi pi-home', path: '/dashboard', tooltip: 'Dashboard' },
        { label: 'Job List', icon: 'pi pi-list', path: '/jobs', tooltip: 'Job List' },
        { label: 'API Keys', icon: 'pi pi-key', path: '/api-keys', tooltip: 'API Keys' },
        { label: 'OAuth', icon: 'pi pi-shield', path: '/oauth', tooltip: 'OAuth' },
        { label: 'Billing', icon: 'pi pi-dollar', path: '/billing', tooltip: 'Billing' },
    ];

    const accountItems = [
        { label: 'Account', icon: 'pi pi-cog', path: '/account', tooltip: 'Account' },
        { label: 'Logout', icon: 'pi pi-sign-out', path: '/logout', tooltip: 'Logout' },
    ];

    return (
        <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            <aside className={`sticky top-0 transition-all duration-300 border-r-[1px] border-grey-300 ${isCollapsed ? 'w-20' : 'w-64'} bg-gray-50 text-black flex flex-col justify-between`}>
                <div className="flex items-center justify-between px-4 py-6">
                    <img src={logo} alt="DocTransit Logo" className={`${isCollapsed ? 'hidden' : 'block'}`} width="140" />
                    <button onClick={toggleSidebar} className="focus:outline-none">
                        <i className={`pi ${isCollapsed ? 'pi-chevron-right' : 'pi-chevron-left'}`} />
                    </button>
                </div>
                <nav className="flex-1 px-4 relative">
                    <ul>
                        {menuItems.map((item) => (
                            <li key={item.path} className="relative group">
                                <Link
                                    to={item.path}
                                    className={`flex items-center px-4 py-2 rounded mb-2 hover:bg-gray-100 active:bg-gray-200 ${location.pathname === item.path ? 'bg-gray-200' : ''}`}
                                >
                                    <i className={`${item.icon} ${isCollapsed ? 'mx-auto' : 'mr-2'}`} />
                                    <span className={`${isCollapsed ? 'hidden' : 'inline-block'}`}>{item.label}</span>
                                </Link>
                                {isCollapsed && <Tooltip content={item.tooltip} isCollapsed={isCollapsed} />} {/* Conditionally render Tooltip */}
                            </li>
                        ))}
                    </ul>
                </nav>
                <div className={`p-4 border-t border-gray-300 ${isCollapsed ? 'px-2' : ''}`}>
                    {/* User account accordion */}
                    <button
                        onClick={() => setAccordionOpen(!isAccordionOpen)}
                        className={`w-full flex items-center px-4 py-2 rounded hover:bg-gray-100 active:bg-gray-200 focus:outline-none ${isCollapsed ? 'justify-center' : ''}`}
                    >
                        <i className="pi pi-user" />
                        {!isCollapsed && <span className="ml-2">My Account</span>}
                        <i className={`ml-auto ${isAccordionOpen ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}`} />
                    </button>
                    {(isCollapsed && isAccordionOpen) || (!isCollapsed && isAccordionOpen) ? (
                        <div className={`mt-2 ${isCollapsed ? 'text-center' : ''}`}>
                            {accountItems.map((item) => (
                                <Link to={item.path} key={item.path} className={`block px-4 py-2 flex items-center rounded mb-2 hover:bg-gray-100 active:bg-gray-200 ${location.pathname === item.path ? 'bg-gray-200' : ''} ${isCollapsed ? 'justify-center' : ''}`}>
                                    <i className={`${item.icon} ${isCollapsed ? 'mx-auto' : 'mr-2'}`} />
                                    <span className={`${isCollapsed ? 'hidden' : 'inline-block'}`}>{item.label}</span>
                                    {isCollapsed && <Tooltip content={item.tooltip} isCollapsed={isCollapsed} />}
                                </Link>
                            ))}
                        </div>
                    ) : null}
                </div>
            </aside>

            {/* Main content */}
            <main className="flex-1 overflow-y-auto p-6">{children}</main>
        </div>
    );
};

export default DashboardLayout;
