// Dashboard.js
import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import DashboardLayout from '../components/DashboardLayout';

const Dashboard = () => {
    const [selectedTimeRange, setSelectedTimeRange] = useState('Monthly');

    const timeRanges = ['Daily', 'Weekly', 'Monthly', 'Yearly'];

    // Mock data for the chart
    const chartData = {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
            {
                label: 'API Usage',
                data: [75, 85, 65, 95, 80],
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                tension: 0.4
            }
        ]
    };

    // Mock data for the metrics
    const creditsRemaining = 500;
    const successfulConversions = 350;
    const failedConversions = 20;
    const creditsConsumed = 1200;
    const totalFileSizeConverted = '1.2TB';

    return (
        <DashboardLayout>
            <header className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-semibold">Dashboard</h1>
                <Dropdown
                    value={selectedTimeRange}
                    options={timeRanges}
                    onChange={(e) => setSelectedTimeRange(e.value)}
                    placeholder="Select Time Range"
                    className="text-sm"
                />
            </header>

            <div className="grid grid-cols-1 gap-4">
                {/* Credits Remaining */}
                <Card title={<span className="text-sm font-normal">Credits Remaining</span>} className="bg-gray-50 rounded-lg shadow-none border-[1px] border-grey-300">
                    <div className="text-2xl font-normal mt-2">{creditsRemaining}</div>
                </Card>

                {/* Chart */}
                <Card title={<span className="text-sm font-normal">API Usage Over Time</span>} className="bg-gray-50 rounded-lg shadow-none border-[1px] border-grey-300">
                    <Chart className="" type="line" data={chartData} options={{ aspectRatio: 2 }} />
                </Card>

                {/* Conversion Statistics */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <Card title={<span className="text-sm font-normal">Successful Conversions</span>} className="bg-gray-50 rounded-lg shadow-none border-[1px] border-grey-300">
                        <div className="text-2xl font-normal mt-2">{successfulConversions}</div>
                    </Card>
                    <Card title={<span className="text-sm font-normal">Failed Conversions</span>} className="bg-gray-50 rounded-lg shadow-none border-[1px] border-grey-300">
                        <div className="text-2xl font-normal mt-2">{failedConversions}</div>
                    </Card>
                    <Card title={<span className="text-sm font-normal">Credits Consumed</span>} className="bg-gray-50 rounded-lg shadow-none border-[1px] border-grey-300">
                        <div className="text-2xl font-normal mt-2">{creditsConsumed}</div>
                    </Card>
                </div>

                {/* Total File Size Converted */}
                <Card title={<span className="text-sm font-normal">Total File Size Converted</span>} className="bg-gray-50 rounded-lg shadow-none border-[1px] border-grey-300">
                    <div className="text-2xl font-normal mt-2">{totalFileSizeConverted}</div>
                </Card>
            </div>
        </DashboardLayout>
    );
};

export default Dashboard;
