// Oauth.js
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import DashboardLayout from '../components/DashboardLayout'; // Adjust the path

const Oauth = () => {
    const [isCreateDialogVisible, setIsCreateDialogVisible] = useState(false);
    const [isRevealDialogVisible, setIsRevealDialogVisible] = useState(false);
    const [clientDetails, setClientDetails] = useState({ name: '', redirectUrl: '' });
    const [selectedClientSecret, setSelectedClientSecret] = useState('');
    const [isLiveMode, setIsLiveMode] = useState(true);

    const oauthClients = [
        { name: 'Client1', clientId: 'abc123', secret: '••••••••', redirectUrl: 'https://example.com/callback1' },
        { name: 'Client2', clientId: 'xyz456', secret: '••••••••', redirectUrl: 'https://example.com/callback2' }
    ];

    const createClient = () => {
        // Logic for creating a new client
        setIsCreateDialogVisible(false);
    };

    const revealSecret = (clientId) => {
        // Logic for revealing the secret for a given client
        setSelectedClientSecret('Actual_Secret_For_' + clientId);
        setIsRevealDialogVisible(true);
    };

    const closeRevealDialog = () => {
        setSelectedClientSecret('');
        setIsRevealDialogVisible(false);
    };

    return (
        <DashboardLayout>
            <header className="flex justify-between items-center mb-8">
                <h2 className="text-3xl font-bold">OAuth</h2>
                <div className="flex items-center space-x-4">
                    <span>Mode:</span>
                    <InputSwitch checked={isLiveMode} onChange={(e) => setIsLiveMode(e.value)} />
                    <span>{isLiveMode ? 'Live' : 'Sandbox'}</span>
                </div>
            </header>

            <Button label="Create New Client" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all mb-6" onClick={() => setIsCreateDialogVisible(true)} />

            <table className="w-full border-collapse mt-8">
                <thead>
                    <tr>
                        <th className="text-left p-2 border-b border-gray-300">Name</th>
                        <th className="text-left p-2 border-b border-gray-300">Client ID</th>
                        <th className="text-left p-2 border-b border-gray-300">Secret</th>
                        <th className="text-left p-2 border-b border-gray-300">Redirect URL</th>
                        <th className="text-left p-2 border-b border-gray-300">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {oauthClients.map((client, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                            <td className="p-2">{client.name}</td>
                            <td className="p-2">{client.clientId}</td>
                            <td className="p-2"><Button label="Reveal" className="p-button-link" onClick={() => revealSecret(client.clientId)} /></td>
                            <td className="p-2">{client.redirectUrl}</td>
                            <td className="p-2 flex space-x-2">
                                <Button label="Edit" className="p-button-sm p-button-secondary" />
                                <Button label="Delete" className="p-button-sm p-button-danger" />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Create Client Dialog */}
            <Dialog header="Create New OAuth Client" visible={isCreateDialogVisible} onHide={() => setIsCreateDialogVisible(false)}>
                <div className="mb-4">
                    <label htmlFor="client-name" className="block mb-2">Name</label>
                    <InputText id="client-name" value={clientDetails.name} onChange={(e) => setClientDetails({ ...clientDetails, name: e.target.value })} className="w-full" />
                </div>
                <div className="mb-4">
                    <label htmlFor="redirect-url" className="block mb-2">Redirect URL</label>
                    <InputText id="redirect-url" value={clientDetails.redirectUrl} onChange={(e) => setClientDetails({ ...clientDetails, redirectUrl: e.target.value })} className="w-full" placeholder="Separate multiple by ','" />
                </div>
                <Button label="Create" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all" onClick={createClient} />
            </Dialog>

            {/* Reveal Secret Dialog */}
            <Dialog header="Client Secret" visible={isRevealDialogVisible} onHide={closeRevealDialog}>
                <p>{selectedClientSecret}</p>
                <Button label="Close" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all" onClick={closeRevealDialog} />
            </Dialog>
        </DashboardLayout>
    );
};

export default Oauth;
