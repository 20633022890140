//DropdownComponent.js
import React, { useState, useRef, useEffect } from 'react';

// Mapping of category names to corresponding PrimeIcons classes
const categoryIcons = {
    Archive: 'pi pi-folder',
    Audio: 'pi pi-volume-up',
    Document: 'pi pi-file',
    Ebook: 'pi pi-book',
    Font: 'pi pi-file',
    Image: 'pi pi-image',
    Other: 'pi pi-file',
    Presentation: 'pi pi-desktop',
    Spreadsheet: 'pi pi-table',
    Vector: 'pi pi-sitemap',
    Video: 'pi pi-video',
};

// Dropdown component to handle selection of file types
const DropdownComponent = ({ className, onChange }) => {
    const [isOpen, setIsOpen] = useState(false); // State to track dropdown visibility
    const [activeCategory, setActiveCategory] = useState('Archive'); // Current active category
    const [selectedFileType, setSelectedFileType] = useState(''); // Selected file type
    const [searchTerm, setSearchTerm] = useState(''); // Search input value
    const dropdownRef = useRef(null); // Ref to capture click outside event

    // List of categories for display in the dropdown
    const categories = Object.keys(categoryIcons);

    // Mapping of each category to its file types
    const fileTypes = {
        Archive: ['7Z', 'ACE', 'ALZ', 'ARC', 'ARJ', 'BZ', 'BZ2', 'CAB', 'CPIO', 'DEB', 'DMG', 'GZ', 'IMG', 'ISO', 'JAR', 'LHA', 'LZ', 'LZMA', 'LZO', 'RAR', 'RPM'],
        Audio: ['MP3', 'WAV', 'FLAC', 'AAC', 'OGG', 'WMA'],
        Document: ['PDF', 'DOCX', 'ODT', 'RTF'],
        Ebook: ['None'],
        Font: ['None'],
        Image: ['None'],
        Other: ['None'],
        Presentation: ['None'],
        Spreadsheet: ['None'],
        Vector: ['None'],
        Video: ['None']
    };

    // Closes dropdown if clicked outside the component
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    // Effect to manage click outside event listener
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Retrieves all file types across categories that match the search term
    const getAllFileTypes = () => {
        return Object.keys(fileTypes)
            .flatMap(category => fileTypes[category])
            .filter(type => type.toLowerCase().includes(searchTerm.toLowerCase()));
    };

    // Clears search input and resets the search term
    const clearSearch = () => {
        setSearchTerm('');
    };

    // Determines whether to show search results or file types of the active category
    const showSearchResults = searchTerm.trim().length > 0;
    const filteredFileTypes = showSearchResults ? getAllFileTypes() : fileTypes[activeCategory];

    // Handles file type selection from the dropdown
    const handleSelect = (type) => {
        setSelectedFileType(type);
        setIsOpen(false); // Close dropdown after selection
        if (onChange) onChange(type); // Call onChange prop if provided
    };

    return (
        <div className={`relative inline-block ${className}`} ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)} // Toggles dropdown visibility
                className="bg-white text-gray-700 py-2 px-4 rounded border border-gray-300 inline-flex items-center space-x-2 w-full hover:bg-gray-100 active:bg-gray-200 transition-all"
            >
                <span className="flex-grow text-left">{selectedFileType || 'Select'}</span> {/* Display selected file type or default text */}
                <i className="pi pi-chevron-down flex-shrink-0 flex items-center text-gray-400" />
            </button>
            {isOpen && (
                <div className="border-[1px] border-gray-300 absolute left-0 mt-2 bg-white text-gray-900 shadow-lg rounded flex flex-col z-40 max-h-72 overflow-hidden w-96">
                    {/* Search field */}
                    <div className="sticky top-0 bg-white z-50 border-b border-gray-200 p-2 flex">
                        <input
                            type="text"
                            placeholder="Search file type..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Updates search term
                            className="flex-grow p-2 border border-gray-300 rounded"
                        />
                        {searchTerm && (
                            <button
                                onClick={clearSearch}
                                className="ml-2 p-2 text-gray-500 hover:text-gray-700 active:text-gray-900 transition-all"
                            >
                                <i className="pi pi-times" />
                            </button>
                        )}
                    </div>

                    {/* Display filtered file types */}
                    {showSearchResults ? (
                        <div className="flex-grow p-3 overflow-y-auto">
                            {filteredFileTypes.map((type, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleSelect(type)} // Handle file type selection
                                    className="bg-white hover:bg-gray-100 active:bg-gray-200 text-gray-900 py-2 px-4 rounded border border-gray-200 mb-2 w-full text-left transition-all"
                                >
                                    {type}
                                </button>
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-grow overflow-y-auto">
                            {/* Left column for categories */}
                            <ul className="flex-none w-40 border-r border-gray-200 overflow-y-auto">
                                {categories.map((category) => (
                                    <li
                                        key={category}
                                        className={`flex items-center py-2 px-4 cursor-pointer hover:bg-gray-100 active:bg-gray-200 ${
                                            activeCategory === category
                                                ? 'bg-gray-100 font-bold'
                                                : ''
                                        } transition-all`}
                                        onClick={() => setActiveCategory(category)} // Change active category
                                    >
                                        <i className={`${categoryIcons[category]} mr-2`} />
                                        {category}
                                    </li>
                                ))}
                            </ul>

                            {/* Right column for file types */}
                            <div className="flex-grow p-3 overflow-y-auto">
                                {filteredFileTypes.map((type, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleSelect(type)} // Handle file type selection
                                        className="bg-white hover:bg-gray-100 active:bg-gray-200 text-gray-900 py-2 px-4 rounded border border-gray-200 mb-2 w-full text-left transition-all"
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DropdownComponent;
