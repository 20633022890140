// DesignSystem.js
import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import DashboardLayout from '../components/DashboardLayout';

const DesignSystem = () => {
    // Dashboard data
    const [selectedTimeRange, setSelectedTimeRange] = useState('Monthly');
    const timeRanges = ['Daily', 'Weekly', 'Monthly', 'Yearly'];
    const chartData = {
        labels: ['January', 'February', 'March', 'April', 'May'],
        datasets: [
            {
                label: 'API Usage',
                data: [75, 85, 65, 95, 80],
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                tension: 0.4
            }
        ]
    };
    const creditsRemaining = 500;
    const successfulConversions = 350;
    const failedConversions = 20;
    const creditsConsumed = 1200;
    const totalFileSizeConverted = '1.2TB';

    // Jobs data
    const [searchTerm, setSearchTerm] = useState('');
    const [isLiveMode, setIsLiveMode] = useState(true);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const jobs = [
        { id: '1', tag: 'Job1', status: 'Completed', created: '2024-05-02 14:00' },
        { id: '2', tag: 'Job2', status: 'Failed', created: '2024-05-03 10:30' },
        { id: '3', tag: 'Job3', status: 'In Progress', created: '2024-05-04 09:15' }
    ];
    const filteredJobs = jobs.filter(
        (job) => job.id.includes(searchTerm) || job.tag.includes(searchTerm)
    );
    const paginatedJobs = filteredJobs.slice(first, first + rows);
    const handlePageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    // API Keys data
    const [apiKeys, setApiKeys] = useState([]);
    const [showCreateKeyDialog, setShowCreateKeyDialog] = useState(false);
    const [newKeyName, setNewKeyName] = useState('');
    const [selectedScopes, setSelectedScopes] = useState([]);
    const [showTokenDialog, setShowTokenDialog] = useState(false);
    const [personalAccessToken, setPersonalAccessToken] = useState('');
    const scopes = ['Read', 'Write', 'Delete'];
    const handleCreateKey = () => setShowCreateKeyDialog(true);
    const handleCreateKeyConfirm = () => {
        const newKey = {
            id: apiKeys.length + 1,
            name: newKeyName,
            scopes: selectedScopes,
            created: new Date().toLocaleString()
        };
        setApiKeys([...apiKeys, newKey]);
        setPersonalAccessToken(`sk_${Math.random().toString(36).substring(2)}`);
        setShowCreateKeyDialog(false);
        setShowTokenDialog(true);
    };
    const handleDeleteKey = (keyId) => setApiKeys(apiKeys.filter((key) => key.id !== keyId));

    // OAuth Clients data
    const [isCreateDialogVisible, setIsCreateDialogVisible] = useState(false);
    const [isRevealDialogVisible, setIsRevealDialogVisible] = useState(false);
    const [clientDetails, setClientDetails] = useState({ name: '', redirectUrl: '' });
    const [selectedClientSecret, setSelectedClientSecret] = useState('');
    const oauthClients = [
        { name: 'Client1', clientId: 'abc123', secret: '••••••••', redirectUrl: 'https://example.com/callback1' },
        { name: 'Client2', clientId: 'xyz456', secret: '••••••••', redirectUrl: 'https://example.com/callback2' }
    ];
    const createClient = () => setIsCreateDialogVisible(false);
    const revealSecret = (clientId) => {
        setSelectedClientSecret('Actual_Secret_For_' + clientId);
        setIsRevealDialogVisible(true);
    };
    const closeRevealDialog = () => {
        setSelectedClientSecret('');
        setIsRevealDialogVisible(false);
    };

    // Account data
    const [email, setEmail] = useState('');

    return (
        <DashboardLayout>
            <h2 className="text-3xl font-bold mb-8">Design System</h2>

            {/* Dashboard */}
            <section>
                <header className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold">Dashboard</h3>
                    <Dropdown
                        value={selectedTimeRange}
                        options={timeRanges}
                        onChange={(e) => setSelectedTimeRange(e.value)}
                        placeholder="Select Time Range"
                        className="text-sm"
                    />
                </header>

                <Card title="Credits Remaining" className="bg-gray-50 rounded-lg shadow-none">
                    <div className="text-2xl font-normal mt-2">{creditsRemaining}</div>
                </Card>

                <Card title="API Usage Over Time" className="bg-gray-50 rounded-lg shadow-none">
                    <Chart type="line" data={chartData} options={{ aspectRatio: 2 }} />
                </Card>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <Card title="Successful Conversions" className="bg-gray-50 rounded-lg shadow-none">
                        <div className="text-2xl font-normal mt-2">{successfulConversions}</div>
                    </Card>
                    <Card title="Failed Conversions" className="bg-gray-50 rounded-lg shadow-none">
                        <div className="text-2xl font-normal mt-2">{failedConversions}</div>
                    </Card>
                    <Card title="Credits Consumed" className="bg-gray-50 rounded-lg shadow-none">
                        <div className="text-2xl font-normal mt-2">{creditsConsumed}</div>
                    </Card>
                </div>

                <Card title="Total File Size Converted" className="bg-gray-50 rounded-lg shadow-none">
                    <div className="text-2xl font-normal mt-2">{totalFileSizeConverted}</div>
                </Card>
            </section>

            {/* Job List */}
            <section>
                <header className="flex flex-wrap justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold">Job List</h3>
                    <div className="flex items-center space-x-4">
                        <span>Mode:</span>
                        <InputSwitch checked={isLiveMode} onChange={(e) => setIsLiveMode(e.value)} />
                        <span>{isLiveMode ? 'Live' : 'Sandbox'}</span>
                        <InputText
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="p-2 border border-gray-300 rounded"
                        />
                        <Button
                            label="Refresh"
                            className="p-button-sm p-button-primary"
                            onClick={() => {}}
                        />
                    </div>
                </header>

                <Card className="bg-gray-50 rounded-lg shadow-none">
                    <table className="w-full border-collapse">
                        <thead>
                            <tr>
                                <th className="text-left p-2 border-b border-gray-300">Job ID</th>
                                <th className="text-left p-2 border-b border-gray-300">Tag</th>
                                <th className="text-left p-2 border-b border-gray-300">Status</th>
                                <th className="text-left p-2 border-b border-gray-300">Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedJobs.map((job) => (
                                <tr key={job.id} className="hover:bg-gray-100">
                                    <td className="p-2 border-b border-gray-300">{job.id}</td>
                                    <td className="p-2 border-b border-gray-300">{job.tag}</td>
                                    <td className="p-2 border-b border-gray-300">{job.status}</td>
                                    <td className="p-2 border-b border-gray-300">{job.created}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card>
                <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={filteredJobs.length}
                    rowsPerPageOptions={[5, 10, 20]}
                    onPageChange={handlePageChange}
                    className="mt-4"
                />
            </section>

            {/* API Keys */}
            <section>
                <header className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold">API Keys</h3>
                    <div className="flex items-center space-x-4">
                        <span>Mode:</span>
                        <InputSwitch checked={isLiveMode} onChange={(e) => setIsLiveMode(e.value)} />
                        <span>{isLiveMode ? 'Live' : 'Sandbox'}</span>
                        <Button label="Create API Key" className="bg-blue-600 text-white hover:bg-blue-700 transition-all" onClick={handleCreateKey} />
                    </div>
                </header>

                <Card className="bg-gray-50 rounded-lg shadow-none">
                    <table className="w-full border-collapse">
                        <thead>
                            <tr>
                                <th className="text-left p-2">API Key Name</th>
                                <th className="text-left p-2">Scopes</th>
                                <th className="text-left p-2">Created</th>
                                <th className="text-right p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {apiKeys.map((key) => (
                                <tr key={key.id} className="hover:bg-gray-100">
                                    <td className="p-2">{key.name}</td>
                                    <td className="p-2">{key.scopes.join(', ')}</td>
                                    <td className="p-2">{key.created}</td>
                                    <td className="p-2 text-right">
                                        <Button
                                            label="Delete"
                                            className="p-button-sm bg-red-600 text-white hover:bg-red-700 transition-all"
                                            onClick={() => handleDeleteKey(key.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card>

                {/* Create API Key Dialog */}
                <Dialog header="Create API Key" visible={showCreateKeyDialog} onHide={() => setShowCreateKeyDialog(false)} footer={
                    <Button label="Create Key" className="bg-blue-600 text-white hover:bg-blue-700 transition-all" onClick={handleCreateKeyConfirm} />
                }>
                    <div className="mb-4">
                        <label htmlFor="keyName" className="block text-gray-600 mb-2">Key Name</label>
                        <InputText id="keyName" value={newKeyName} onChange={(e) => setNewKeyName(e.target.value)} className="w-full p-2 border border-gray-300 rounded" />
                    </div>
                    <div>
                        <label className="block text-gray-600 mb-2">Scopes</label>
                        {scopes.map((scope) => (
                            <div key={scope} className="flex items-center mb-2">
                                <Checkbox inputId={scope} value={scope} onChange={(e) => {
                                    const updatedScopes = e.checked ? [...selectedScopes, scope] : selectedScopes.filter(s => s !== scope);
                                    setSelectedScopes(updatedScopes);
                                }} checked={selectedScopes.includes(scope)} />
                                <label htmlFor={scope} className="ml-2">{scope}</label>
                            </div>
                        ))}
                    </div>
                </Dialog>

                {/* Personal Access Token Dialog */}
                <Dialog header="Personal Access Token" visible={showTokenDialog} onHide={() => setShowTokenDialog(false)}>
                    <div className="mb-4">
                        <label className="block text-gray-600 mb-2">Your new API Key</label>
                        <div className="p-4 bg-gray-100 rounded">{personalAccessToken}</div>
                    </div>
                    <Button label="Close" className="bg-blue-600 text-white hover:bg-blue-700 transition-all" onClick={() => setShowTokenDialog(false)} />
                </Dialog>
            </section>

            {/* OAuth Clients */}
            <section>
                <header className="flex justify-between items-center mb-8">
                    <h3 className="text-xl font-semibold">OAuth</h3>
                    <div className="flex items-center space-x-4">
                        <span>Mode:</span>
                        <InputSwitch checked={isLiveMode} onChange={(e) => setIsLiveMode(e.value)} />
                        <span>{isLiveMode ? 'Live' : 'Sandbox'}</span>
                    </div>
                </header>

                <Button label="Create New Client" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all mb-6" onClick={() => setIsCreateDialogVisible(true)} />

                <table className="w-full border-collapse mt-8">
                    <thead>
                        <tr>
                            <th className="text-left p-2 border-b border-gray-300">Name</th>
                            <th className="text-left p-2 border-b border-gray-300">Client ID</th>
                            <th className="text-left p-2 border-b border-gray-300">Secret</th>
                            <th className="text-left p-2 border-b border-gray-300">Redirect URL</th>
                            <th className="text-left p-2 border-b border-gray-300">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oauthClients.map((client, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="p-2">{client.name}</td>
                                <td className="p-2">{client.clientId}</td>
                                <td className="p-2"><Button label="Reveal" className="p-button-link" onClick={() => revealSecret(client.clientId)} /></td>
                                <td className="p-2">{client.redirectUrl}</td>
                                <td className="p-2 flex space-x-2">
                                    <Button label="Edit" className="p-button-sm p-button-secondary" />
                                    <Button label="Delete" className="p-button-sm p-button-danger" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Create Client Dialog */}
                <Dialog header="Create New OAuth Client" visible={isCreateDialogVisible} onHide={() => setIsCreateDialogVisible(false)}>
                    <div className="mb-4">
                        <label htmlFor="client-name" className="block mb-2">Name</label>
                        <InputText id="client-name" value={clientDetails.name} onChange={(e) => setClientDetails({ ...clientDetails, name: e.target.value })} className="w-full" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="redirect-url" className="block mb-2">Redirect URL</label>
                        <InputText id="redirect-url" value={clientDetails.redirectUrl} onChange={(e) => setClientDetails({ ...clientDetails, redirectUrl: e.target.value })} className="w-full" placeholder="Separate multiple by ','" />
                    </div>
                    <Button label="Create" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all" onClick={createClient} />
                </Dialog>

                {/* Reveal Secret Dialog */}
                <Dialog header="Client Secret" visible={isRevealDialogVisible} onHide={closeRevealDialog}>
                    <p>{selectedClientSecret}</p>
                    <Button label="Close" className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all" onClick={closeRevealDialog} />
                </Dialog>
            </section>

            {/* Account */}
            <section>
                <h3 className="text-xl font-semibold mb-8">Account</h3>

                <Card className="mb-8 p-4 bg-gray-50 shadow-none rounded-lg">
                    <h3 className="text-xl font-semibold mb-4">Update Email</h3>
                    <div className="flex flex-col space-y-4">
                        <InputText
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter new email"
                            className="p-2 border border-gray-300 rounded"
                        />
                        <Button
                            label="Update Email"
                            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 active:bg-blue-800 transition-all"
                            onClick={() => {}}
                        />
                    </div>
                </Card>

                <Card className="mb-8 p-4 bg-gray-50 shadow-none rounded-lg">
                    <h3 className="text-xl font-semibold mb-4">Reset Password</h3>
                    <Button
                        label="Reset Password"
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 active:bg-blue-800 transition-all"
                        onClick={() => {}}
                    />
                </Card>

                <Card className="mb-8 p-4 bg-gray-50 shadow-none rounded-lg">
                    <h3 className="text-xl font-semibold mb-4">Delete Account</h3>
                    <p className="text-red-600 mb-4">
                        Warning: Deleting your account will remove all your data and is irreversible.
                    </p>
                    <Button
                        label="Delete Account"
                        className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 active:bg-red-800 transition-all"
                        onClick={() => {}}
                    />
                </Card>
            </section>

            <section>

            </section>
        </DashboardLayout>
    );
};

export default DesignSystem;
