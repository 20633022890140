// Billing.js
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import DashboardLayout from '../components/DashboardLayout'; // Adjust the path

const Billing = () => {
    const [autoRefill, setAutoRefill] = useState(false);

    const buttonClass = "bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all";

    return (
        <DashboardLayout>
            <h2 className="text-3xl font-bold mb-8">Billing</h2>

            {/* Current Billing Plan */}
            <Card className="mb-6 p-4 bg-gray-50 shadow-none rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Current Billing Plan</h3>
                <div className="flex justify-between items-center">
                    <span className="text-lg">Premium Plan</span>
                    <Button label="Change Plan" className={`p-button-sm p-button ${buttonClass}`} />
                </div>
            </Card>

            {/* Auto Refill Switch */}
            <Card className="mb-6 p-4 bg-gray-50 shadow-none rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Auto Refill</h3>
                <div className="flex items-center space-x-4">
                    <span>Enable Auto Refill</span>
                    <InputSwitch checked={autoRefill} onChange={(e) => setAutoRefill(e.value)} />
                </div>
            </Card>

            {/* Payment History / Invoices */}
            <Card className="mb-6 p-4 bg-gray-50 shadow-none rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Payment History / Invoices</h3>
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="text-left p-2">Invoice ID</th>
                            <th className="text-left p-2">Amount</th>
                            <th className="text-left p-2">Status</th>
                            <th className="text-left p-2">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="hover:bg-gray-100">
                            <td className="p-2">#1</td>
                            <td className="p-2">$99</td>
                            <td className="p-2">Paid</td>
                            <td className="p-2">2024-05-01</td>
                        </tr>
                        <tr className="hover:bg-gray-100">
                            <td className="p-2">#2</td>
                            <td className="p-2">$99</td>
                            <td className="p-2">Paid</td>
                            <td className="p-2">2024-06-01</td>
                        </tr>
                        {/* Add more invoice rows */}
                    </tbody>
                </table>
            </Card>

            {/* Payment Method */}
            <Card className="mb-6 p-4 bg-gray-50 shadow-none rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Payment Method</h3>
                <div className="flex justify-between items-center">
                    <span>Visa **** 1234</span>
                    <Button label="Change Method" className={`p-button-sm p-button ${buttonClass}`} />
                </div>
            </Card>

            {/* Billing Address */}
            <Card className="mb-6 p-4 bg-gray-50 shadow-none rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Billing Address</h3>
                <div className="grid grid-cols-1 gap-4">
                    <InputText placeholder="Street Address" className="p-2 border border-gray-300 rounded" />
                    <InputText placeholder="City" className="p-2 border border-gray-300 rounded" />
                    <InputText placeholder="State/Province" className="p-2 border border-gray-300 rounded" />
                    <InputText placeholder="Zip Code" className="p-2 border border-gray-300 rounded" />
                    <InputText placeholder="Country" className="p-2 border border-gray-300 rounded" />
                    <Button label="Update Address" className={`p-button-sm p-button ${buttonClass}`} />
                </div>
            </Card>

            {/* Billing Email Address */}
            <Card className="mb-6 p-4 bg-gray-50 shadow-none rounded-lg">
                <h3 className="text-xl font-semibold mb-4">Billing Email Address</h3>
                <div className="flex items-center gap-4">
                    <InputText placeholder="Email Address" className="flex-grow p-2 border border-gray-300 rounded" />
                    <Button label="Update Email" className={`p-button-sm p-button ${buttonClass}`} />
                </div>
            </Card>
        </DashboardLayout>
    );
};

export default Billing;
