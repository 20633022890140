import React, { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';

const ApiKeys = () => {
    const [isLiveMode, setIsLiveMode] = useState(true);
    const [apiKeys, setApiKeys] = useState([]);
    const [showCreateKeyDialog, setShowCreateKeyDialog] = useState(false);
    const [newKeyName, setNewKeyName] = useState('');
    const [selectedScopes, setSelectedScopes] = useState([]);
    const [showTokenDialog, setShowTokenDialog] = useState(false);
    const [personalAccessToken, setPersonalAccessToken] = useState('');

    const scopes = ['Read', 'Write', 'Delete'];

    const handleCreateKey = () => {
        setShowCreateKeyDialog(true);
    };

    const handleCreateKeyConfirm = () => {
        const newKey = {
            id: apiKeys.length + 1,
            name: newKeyName,
            scopes: selectedScopes,
            created: new Date().toLocaleString(),
        };
        setApiKeys([...apiKeys, newKey]);
        setPersonalAccessToken(`sk_${Math.random().toString(36).substring(2)}`);
        setShowCreateKeyDialog(false);
        setShowTokenDialog(true);
    };

    const handleDeleteKey = (keyId) => {
        setApiKeys(apiKeys.filter((key) => key.id !== keyId));
    };

    return (
        <DashboardLayout>
            <header className="flex justify-between items-center mb-4">
                <h2 className="text-3xl font-bold">API Keys</h2>
                <div className="flex items-center space-x-4">
                    <span>Mode:</span>
                    <InputSwitch checked={isLiveMode} onChange={(e) => setIsLiveMode(e.value)} />
                    <span>{isLiveMode ? 'Live' : 'Sandbox'}</span>
                    <Button label="Create API Key" className="bg-blue-600 text-white hover:bg-blue-700 transition-all" onClick={handleCreateKey} />
                </div>
            </header>

            <Card className="bg-gray-50 rounded-lg shadow-none">
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="text-left p-2">API Key Name</th>
                            <th className="text-left p-2">Scopes</th>
                            <th className="text-left p-2">Created</th>
                            <th className="text-right p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiKeys.map((key) => (
                            <tr key={key.id} className="hover:bg-gray-100">
                                <td className="p-2">{key.name}</td>
                                <td className="p-2">{key.scopes.join(', ')}</td>
                                <td className="p-2">{key.created}</td>
                                <td className="p-2 text-right">
                                    <Button
                                        label="Delete"
                                        className="p-button-sm bg-red-600 text-white hover:bg-red-700 transition-all"
                                        onClick={() => handleDeleteKey(key.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>

            {/* Create API Key Dialog */}
            <Dialog header="Create API Key" visible={showCreateKeyDialog} onHide={() => setShowCreateKeyDialog(false)} footer={
                <Button label="Create Key" className="bg-blue-600 text-white hover:bg-blue-700 transition-all" onClick={handleCreateKeyConfirm} />
            }>
                <div className="mb-4">
                    <label htmlFor="keyName" className="block text-gray-600 mb-2">Key Name</label>
                    <InputText id="keyName" value={newKeyName} onChange={(e) => setNewKeyName(e.target.value)} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div>
                    <label className="block text-gray-600 mb-2">Scopes</label>
                    {scopes.map((scope) => (
                        <div key={scope} className="flex items-center mb-2">
                            <Checkbox inputId={scope} value={scope} onChange={(e) => {
                                const updatedScopes = e.checked ? [...selectedScopes, scope] : selectedScopes.filter(s => s !== scope);
                                setSelectedScopes(updatedScopes);
                            }} checked={selectedScopes.includes(scope)} />
                            <label htmlFor={scope} className="ml-2">{scope}</label>
                        </div>
                    ))}
                </div>
            </Dialog>

            {/* Personal Access Token Dialog */}
            <Dialog header="Personal Access Token" visible={showTokenDialog} onHide={() => setShowTokenDialog(false)}>
                <div className="mb-4">
                    <label className="block text-gray-600 mb-2">Your new API Key</label>
                    <div className="p-4 bg-gray-100 rounded">{personalAccessToken}</div>
                </div>
                <Button label="Close" className="bg-blue-600 text-white hover:bg-blue-700 transition-all" onClick={() => setShowTokenDialog(false)} />
            </Dialog>
        </DashboardLayout>
    );
};

export default ApiKeys;
