import React from 'react';
import 'primereact/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';
import 'tailwindcss/tailwind.css';
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';

function PrivacyPolicy() {
    return (
        <div className="font-sans bg-gray-50">
            {/* Navigation */}
            <TopNav />

            {/* Privacy Policy */}
            <section className="container mx-auto py-20 max-w-[950px] px-4 md:px-8">
                <h2 className="font-syne text-5xl font-bold mb-10">Privacy Policy</h2>
                <div>
                    <h3 className="text-2xl font-semibold mb-4">1. Introduction</h3>
                    <p className="text-gray-600 mb-6">
                        We value your privacy. This Privacy Policy outlines how we collect, use, and protect your information...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">2. Information Collection</h3>
                    <p className="text-gray-600 mb-6">
                        We may collect personal information that you provide directly to us or that we collect automatically...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">3. Use of Information</h3>
                    <p className="text-gray-600 mb-6">
                        Your information is used to improve our services, personalize your experience, and communicate with you...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">4. Sharing of Information</h3>
                    <p className="text-gray-600 mb-6">
                        We do not share your personal information with third parties except as outlined in this policy...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">5. Security</h3>
                    <p className="text-gray-600 mb-6">
                        We take appropriate security measures to protect your information against unauthorized access, alteration, or disclosure...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">6. Changes to Policy</h3>
                    <p className="text-gray-600 mb-6">
                        We may update this Privacy Policy from time to time. Your continued use of our services after changes...
                    </p>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
