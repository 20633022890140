import React from 'react';

const SandboxToggle = ({ isLive, onToggle }) => {
    const sandboxClass = isLive ? 'text-gray-400' : '';
    const liveClass = isLive ? '' : 'text-gray-400';

    return (
        <div className="flex items-center space-x-4">
            <span className={`cursor-pointer ${sandboxClass}`} onClick={() => onToggle(false)}>Sandbox</span>
            <span>|</span>
            <span className={`cursor-pointer ${liveClass}`} onClick={() => onToggle(true)}>Live</span>
        </div>
    );
};

export default SandboxToggle;
