// Account.js
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import DashboardLayout from '../components/DashboardLayout';

const Account = () => {
    const [email, setEmail] = useState('');

    const handleEmailUpdate = () => {
        // Logic for updating email
    };

    const handlePasswordReset = () => {
        // Logic for sending a password reset email
    };

    const handleDeleteAccount = () => {
        // Logic for deleting the account
    };

    const buttonClass = "bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 active:bg-blue-800 transition-all";

    return (
        <DashboardLayout>
            <h2 className="text-3xl font-bold mb-8">Account</h2>

            {/* Update Email */}
            <Card className="mb-8 p-4 bg-gray-50 shadow-none rounded-lg">
                <header className="mb-4"><h3 className="text-xl font-semibold">Update Email</h3></header>
                <div className="space-x-4">
                    <InputText
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter new email"
                        className="p-2 border border-gray-300 rounded"
                    />
                    <Button
                        label="Update Email"
                        className={buttonClass}
                        onClick={handleEmailUpdate}
                    />
                </div>
            </Card>

            {/* Reset Password */}
            <Card className="mb-8 p-4 bg-gray-50 shadow-none rounded-lg">
                <header className="mb-4"><h3 className="text-xl font-semibold">Reset Password</h3></header>
                <Button
                    label="Reset Password"
                    className={buttonClass}
                    onClick={handlePasswordReset}
                />
            </Card>

            {/* Delete Account */}
            <Card className="mb-8 p-4 bg-gray-50 shadow-none rounded-lg">
                <header className="mb-4"><h3 className="text-xl font-semibold">Delete Account</h3></header>
                <p className="text-red-600 mb-4">
                    Warning: Deleting your account will remove all your data and is irreversible.
                </p>
                <Button
                    label="Delete Account"
                    className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 active:bg-red-800 transition-all"
                    onClick={handleDeleteAccount}
                />
            </Card>
        </DashboardLayout>
    );
};

export default Account;
