import React from 'react';
import 'primereact/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';
import 'tailwindcss/tailwind.css';
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';

function ContactUs() {
    return (
        <div className="font-sans bg-gray-50">
            {/* Navigation */}
            <TopNav />

            {/* Contact Us */}
            <section className="container mx-auto py-20 max-w-[950px] px-4 md:px-8">
                <h2 className="font-syne text-5xl font-bold mb-10">Contact Us</h2>
                <div>
                    <h3 className="text-2xl font-semibold mb-4">We'd Love to Hear From You!</h3>
                    <p className="text-gray-600 mb-6">
                        If you have any questions, feedback, or need assistance, please reach out to us:
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">Email</h3>
                    <p className="text-gray-600 mb-6">
                        You can email us at <a href="mailto:support@doctransit.com" className="text-blue-600 underline">support@doctransit.com</a>
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">Phone</h3>
                    <p className="text-gray-600 mb-6">
                        Call us at (123) 456-7890
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">Address</h3>
                    <p className="text-gray-600 mb-6">
                        123 DocTransit St, Suite 100<br />
                        City, State, Zipcode
                    </p>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default ContactUs;
