import React from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import DropdownComponent from './components/DropdownComponent';
import 'primereact/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';
import 'tailwindcss/tailwind.css';
import backgroundImage from './assets/background-image.jpeg';
import TopNav from './components/TopNav';
import Footer from './components/Footer';
import Dropzone from './components/Dropzone';

function App() {
  return (
    <div className="font-sans bg-gray-50">
      <TopNav />

      {/* Hero Section */}
      <div className="relative w-full h-screen mt-[-56px]" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
        <section className="flex items-center h-full justify-center px-0 md:justify-start md:pl-20 "> {/* Adjusted alignment */}
          <Dropzone />
        </section>
      </div>

                {/* App features */}
                <section className="container mx-auto text-center py-20 max-w-[950px]">
                    <h2 className="font-syne text-5xl font-bold mb-20">The Easiest Way to Convert Files</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-4">
                        <div className="p-8 rounded-xl text-left">
                            <h3 className="text-2xl font-semibold mb-4">Security</h3>
                            <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className="p-8 rounded-xl text-left">
                            <h3 className="text-2xl font-semibold mb-4">Security</h3>
                            <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className="p-8 rounded-xl text-left">
                            <h3 className="text-2xl font-semibold mb-4">Security</h3>
                            <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                        <div className="p-8 rounded-xl text-left">
                            <h3 className="text-2xl font-semibold mb-4 hover:text-blue-600 transition-all">Security</h3>
                            <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </section>

                {/* API Advert */}
                <section className="py-10 px-4 md:px-8 mx-auto max-w-[950px]">
                    <div className="container bg-white mx-auto border border-gray-300 rounded-lg p-12">
                        <h2 className="font-syne text-3xl font-bold text-center mb-6">Convert files over API</h2>
                        <p className="text-center mb-6 text-gray-600">Some description about API conversion...</p>
                        <div className="flex justify-center">
                            <button className="bg-blue-600 text-white py-2 px-4 rounded mr-2 hover:bg-blue-700 active:bg-blue-800 transition-all">Get Pricing</button>
                            <button className="border border-blue-600 text-blue-600 py-2 px-4 rounded hover:bg-blue-50 active:bg-blue-100 transition-all">Documentation</button>
                        </div>
                    </div>
                </section>

                {/* FAQ */}
                <section className="container mx-auto py-10 px-4 md:px-8 max-w-[950px]">
                    <h2 className="font-syne text-3xl font-bold text-center mb-10">Have questions about converting your files?</h2>
                    <div>
                        <details>
                            <summary className="flex justify-between items-center font-bold border-t-[1px] border-gray-300 py-4 hover:text-blue-600 transition-all">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                                <svg className="w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                                </svg>
                            </summary>
                            <p className="text-gray-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </details>

                        <details>
                            <summary className="flex justify-between items-center font-bold border-t-[1px] border-gray-300 py-4 hover:text-blue-600 transition-all">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                                <svg className="w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                                </svg>
                            </summary>
                            <p className="text-gray-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </details>

                        <details>
                            <summary className="flex justify-between items-center font-bold border-t-[1px] border-gray-300 py-4 hover:text-blue-600 transition-all">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
                                <svg className="w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                                </svg>
                            </summary>
                            <p className="text-gray-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </details>
                    </div>
                </section>

                {/* Footer */}
                <Footer />
</div>
);
}

export default App;
