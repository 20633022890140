import React from 'react';

const Api = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 p-8">
            <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-4xl font-bold mb-8">About Our API</h2>
                <p className="text-lg text-gray-600 mb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt quam eget libero eleifend consectetur. Donec bibendum, libero a ultricies facilisis, libero orci efficitur eros, vel vulputate mauris ligula ac lectus.</p>
                <div className="text-gray-600 text-left">
                    <ul className="mb-6">
                        <li className="mb-2"><strong>Feature 1:</strong> Detailed description of feature 1.</li>
                        <li className="mb-2"><strong>Feature 2:</strong> Detailed description of feature 2.</li>
                        <li><strong>Feature 3:</strong> Detailed description of feature 3.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Api;
