import React, { useState, useRef } from 'react';
import { Button, ProgressBar } from 'primereact';
import DropdownComponent from '../components/DropdownComponent';

const Dropzone = () => {
  const [fileSelected, setFileSelected] = useState(null);
  const [dropdownsSelected, setDropdownsSelected] = useState({ from: '', to: '' });
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [success, setSuccess] = useState(false);

  const inputFileRef = useRef(null);

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileSelected(file);
      // Reset file input to avoid opening the finder window again
      inputFileRef.current.value = '';
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setFileSelected(file);
    }
  };

  const removeFile = () => {
    setFileSelected(null);
    setSuccess(false);
    setProgress(0);
    setUploading(false); // Reset uploading state if in progress
  };

  const handleDropdownChange = (key) => (value) => {
    setDropdownsSelected((prev) => ({ ...prev, [key]: value }));
  };

  const handleConvert = () => {
    setUploading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append('file', fileSelected);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/dtapi-detect', true);

    const progressKey = 'UPLOAD_PROGRESS';
    formData.append('UPLOAD_IDENTIFIER', progressKey);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setProgress(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const disposition = xhr.getResponseHeader('Content-Disposition');
        let filename = 'download.jpg';

        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        const theAnchor = document.createElement('a');
        const theLink = window.URL.createObjectURL(blob);

        theAnchor.href = theLink;
        theAnchor.download = filename;
        document.body.appendChild(theAnchor);
        theAnchor.click();

        window.URL.revokeObjectURL(theLink);
        theAnchor.remove();

        setSuccess(true);
      } else {
        console.error('Upload failed', xhr.statusText);
      }
      setUploading(false);
    };

    xhr.onerror = () => {
      console.error('Upload error');
      setUploading(false);
    };

    xhr.responseType = 'blob';
    xhr.send(formData);

    const updateProgress = async () => {
      try {
        const response = await fetch(`/dtapi-detect?progress_key=${progressKey}`);
        if (response.ok) {
          const progressData = await response.json();
          if (progressData) {
            setProgress((progressData.bytes_processed / progressData.content_length) * 100);
          }
        }
      } catch (error) {
        console.error('Progress update error', error);
      }
    };

    const intervalId = setInterval(updateProgress, 1000);

    xhr.onloadend = () => {
      clearInterval(intervalId);
    };
  };

  const truncateFileName = (name) => {
    const maxLength = 20;
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg h-[402px] w-[432px]"> {/* Adjust min-height to ensure consistent height */}
      {/* Dropzone container */}
      <div
        id="dropzone"
        className={`border-[1px] border-gray-300 rounded w-full transition-all duration-200 min-h-[240px] ${fileSelected ? 'justify-start' : 'justify-center'} flex flex-col items-start space-y-2`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {fileSelected ? (
          uploading ? (
            // Processing state
            <div className="w-full flex items-center p-3 space-x-2">
              <div className="flex items-center space-x-2 w-full">
                <i className="pi pi-file text-gray-600"></i> {/* Add file icon */}
                <span className="text-gray-700 flex-grow">{truncateFileName(fileSelected.name)}</span> {/* Display truncated file name */}
                <ProgressBar value={progress} className="w-[120px]" /> {/* Display progress bar */}
              </div>
            </div>
          ) : success ? (
            // Success state (green checkmark)
            <div className="w-full flex justify-between items-center p-3 border-b border-gray-300"> {/* Update with a consistent layout */}
              <div className="flex items-center space-x-2">
                <i className="pi pi-file text-gray-600"></i> {/* Add file icon */}
                <span className="text-gray-700">{truncateFileName(fileSelected.name)}</span> {/* Display truncated file name */}
              </div>
              <div className="bg-green-500 text-white rounded-full flex items-center justify-center p-1">
                <i className="pi pi-check"></i> {/* Add checkmark icon */}
              </div>
            </div>
          ) : (
            // Display selected file information (file selected state)
            <div className="w-full flex justify-between items-center p-3 border-b border-gray-300"> {/* Update with a consistent layout */}
              <div className="flex items-center space-x-2">
                <i className="pi pi-file text-gray-600"></i> {/* Add file icon */}
                <span className="text-gray-700">{truncateFileName(fileSelected.name)}</span> {/* Display truncated file name */}
              </div>
              <Button icon="pi pi-times" className="p-button-danger p-button-sm" onClick={removeFile} /> {/* X Button */}
            </div>
          )
        ) : (
          // Default dropzone state
          <div className="flex flex-col items-center space-y-4 w-full">
            <svg className="text-blue-600 w-16 h-16" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
            </svg>
            <p className="text-gray-600 text-center">Drop your file here or <span className="text-blue-600 underline">select a file</span></p>
            <Button
              label="Select File"
              className="border border-gray-300 text-gray-600 py-2 px-4 rounded cursor-pointer bg-white hover:bg-gray-100 active:bg-gray-200 transition-all"
              onClick={() => inputFileRef.current && inputFileRef.current.click()} // Ensure inputFileRef is available before clicking it
            />
            {/* Hidden file input */}
            <input ref={inputFileRef} type="file" className="hidden" onChange={handleFileSelection} />
          </div>
        )}
      </div>

      {/* Dropdowns and Convert button */}
      {!uploading && !success && (
        <>
          <div className="flex justify-between items-center mb-4 mt-4">
            <div className="relative flex-grow min-w-[177px]">
              <DropdownComponent onChange={handleDropdownChange('from')} className="w-full" />
            </div>
            <span className="text-gray-500 mx-2">→</span>
            <div className="relative flex-grow min-w-[177px]">
              <DropdownComponent onChange={handleDropdownChange('to')} className="w-full" />
            </div>
          </div>
          <Button
            label="Convert"
            className={`p-button p-button-rounded bg-blue-600 text-white py-2 px-4 rounded w-full text-center hover:bg-blue-700 active:bg-blue-800 transition-all`}
            onClick={handleConvert}
            // Annoying Requirement to Convert: disabled={!fileSelected || !dropdownsSelected.from || !dropdownsSelected.to}
            //disabled={!fileSelected || !dropdownsSelected.to}
            disabled={!fileSelected}
          />
        </>
      )}

      {/* Processing state */}
      {uploading && (
        <>
          <Button
            label="Cancel"
            className="bg-white border border-gray-300 text-gray-600 py-2 px-4 rounded w-full text-center hover:bg-gray-100 active:bg-gray-200 transition-all mt-4"
            onClick={removeFile}
          />
        </>
      )}
      {/* Success state */}
      {success && (
        <div className="flex flex-col items-center mt-4">
          <Button label="Process New File" className="p-button-link mb-4" onClick={removeFile} /> {/* Reset the form */}
          {/* <Button
            label="Download Now"
            className={`p-button p-button-rounded bg-blue-600 text-white py-2 px-4 rounded w-full text-center hover:bg-blue-700 active:bg-blue-800 transition-all`}
            onClick={handleDownload}
          /> */}
        </div>
      )}
    </div>
  );
};

export default Dropzone;
