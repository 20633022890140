import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import DashboardLayout from '../components/DashboardLayout';
import SandboxToggle from '../components/SandboxToggle'; // Import the SandboxToggle component
import 'primeicons/primeicons.css';

const Status = ({ status }) => {
    let colorClass = '';
    let text = '';
    switch (status) {
        case 'Completed':
            colorClass = 'bg-green-500';
            text = 'Completed';
            break;
        case 'Failed':
            colorClass = 'bg-red-500';
            text = 'Failed';
            break;
        case 'In Progress':
            colorClass = 'bg-yellow-500';
            text = 'In Progress';
            break;
        default:
            colorClass = 'bg-gray-500';
            text = 'N/A';
    }
    // Calculate the width based on the length of the text
    const width = text.length * 10; // Adjust the multiplier as needed

    return (
        <div className={`inline-block mr-2 relative rounded-full ${colorClass}`} style={{ width: `${width}px`, height: 'auto', padding: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span className="text-white text-xs">{text}</span>
        </div>
    );
};

const Jobs = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5); // Number of rows per page

    const jobs = [
        { id: '1', tag: 'Job1', status: 'Completed', created: '2024-05-02 14:00' },
        { id: '2', tag: 'Job2', status: 'Failed', created: '2024-05-03 10:30' },
        { id: '3', tag: 'Job3', status: 'In Progress', created: '2024-05-04 09:15' },
        // Add more jobs for testing pagination
        { id: '4', tag: 'Job4', status: 'Completed', created: '2024-05-05 14:00' },
        { id: '5', tag: 'Job5', status: 'Completed', created: '2024-05-06 14:00' },
        { id: '6', tag: 'Job6', status: 'Failed', created: '2024-05-07 10:30' },
        { id: '7', tag: 'Job7', status: 'In Progress', created: '2024-05-08 09:15' }
    ];

    const filteredJobs = jobs.filter(
        (job) => job.id.includes(searchTerm) || job.tag.includes(searchTerm)
    );

    const paginatedJobs = filteredJobs.slice(first, first + rows);

    const handlePageChange = (e) => {
        setFirst(e.first);
        setRows(e.rows);
    };

    return (
        <DashboardLayout>
        <header className="mb-4 flex justify-between items-center">
                <h1 className="text-2xl font-semibold mb-2">Job List</h1>
                <SandboxToggle /> {/* Place the SandboxToggle component here */}
            </header>
            <div className="mb-4">
                <div className="p-input-icon-left">
                    <i className="pi pi-search pl-3" />
                    <InputText
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="p-2 border border-gray-300 rounded pl-9" // Adjust padding to accommodate the icon
                    />
                </div>
            </div>

            <div className="bg-gray-50 rounded-lg shadow-none mb-4">
                <table className="w-full border-collapse">
                    <thead>
                        <tr>
                            <th className="font-medium text-left p-3 border-b border-gray-200">Job ID</th>
                            <th className="font-medium text-left p-3 border-b border-gray-200">Tag</th>
                            <th className="font-medium text-left p-3 border-b border-gray-200">Status</th>
                            <th className="font-medium text-right p-3 border-b border-gray-200">Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedJobs.map((job, index) => (
                            <tr key={job.id} className={`${index === paginatedJobs.length - 1 ? '' : 'border-b border-gray-300'} hover:bg-gray-100`}>
                                <td className="p-3 border-b border-gray-200">{job.id}</td>
                                <td className="p-3 border-b border-gray-200">{job.tag}</td>
                                <td className="p-3 border-b border-gray-200" style={{ textAlign: 'center' }}>
                                    <Status status={job.status} />
                                </td>
                                <td className="p-3 border-b border-gray-200 text-right">{job.created}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Paginator
                first={first}
                rows={rows}
                totalRecords={filteredJobs.length}
                rowsPerPageOptions={[5, 10, 20]}
                onPageChange={handlePageChange}
                className="mt-4"
            />
        </DashboardLayout>
    );
};

export default Jobs;
