import React, { useState } from 'react';
import logo from '../assets/doctransit_logo.svg';
import DropdownMenu from './DropdownMenu';
import 'primeicons/primeicons.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const TopNav = () => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [showConvertMenu, setShowConvertMenu] = useState(false);
  const [showToolsMenu, setShowToolsMenu] = useState(false);
  const [showApiMenu, setShowApiMenu] = useState(false);
  const [mobileSubMenu, setMobileSubMenu] = useState('');

  const toggleMobileMenu = () => setMobileMenuVisible(!mobileMenuVisible);
  const toggleMobileSubMenu = (menu) => setMobileSubMenu((prev) => (prev === menu ? '' : menu));

  const categoryIcons = {
    Archive: 'pi pi-folder',
    Audio: 'pi pi-volume-up',
    Document: 'pi pi-file',
    Ebook: 'pi pi-book',
    Font: 'pi pi-file',
    Image: 'pi pi-image',
    Other: 'pi pi-file',
    Presentation: 'pi pi-desktop',
    Spreadsheet: 'pi pi-table',
    Vector: 'pi pi-sitemap',
    Video: 'pi pi-video',
  };

  const convertItems = [
    { label: 'Archive', href: '/archive', icon: categoryIcons.Archive },
    { label: 'Audio', href: '/audio', icon: categoryIcons.Audio },
    { label: 'Document', href: '/document', icon: categoryIcons.Document },
    { label: 'Ebook', href: '/ebook', icon: categoryIcons.Ebook },
    { label: 'Font', href: '/font', icon: categoryIcons.Font },
    { label: 'Image', href: '/image', icon: categoryIcons.Image },
    { label: 'Other', href: '/other', icon: categoryIcons.Other },
    { label: 'Presentation', href: '/presentation', icon: categoryIcons.Presentation },
    { label: 'Spreadsheet', href: '/spreadsheet', icon: categoryIcons.Spreadsheet },
    { label: 'Vector', href: '/vector', icon: categoryIcons.Vector },
    { label: 'Video', href: '/video', icon: categoryIcons.Video },
  ];

  const toolsItems = [
    { label: 'ZipCleaner', href: 'https://zipcleaner.app', icon: 'pi pi-cog' }, // Adjust icon as needed
  ];

  const apiItems = [
    { label: 'Get the API', href: '/api' },
    { label: 'Pricing', href: '/pricing' },
    { label: 'Documentation', href: '/documentation' },
  ];

  return (
    <header className="sticky top-4 z-50 mx-4 bg-white rounded-lg shadow-md">
      <nav className="flex justify-between items-center p-2 h-14">
        <a href="/" className="flex items-center ml-3">
          <img src={logo} alt="DocTransit Logo" width="140" />
        </a>
        <div className="hidden md:flex flex-grow justify-center items-center space-x-8 relative">
          <div
            className="relative"
            onMouseEnter={() => setShowConvertMenu(true)}
            onMouseLeave={() => setShowConvertMenu(false)}
          >
            <a href="#" className="hover:text-blue-600 active:text-blue-800 transition-all flex items-center py-2">
              Convert <i className="pi pi-chevron-down ml-1 text-gray-400" />
            </a>
            {showConvertMenu && <DropdownMenu items={convertItems} width="300px" styles={{ top: '-5px' }} />}
          </div>

          <div
            className="relative"
            onMouseEnter={() => setShowToolsMenu(true)}
            onMouseLeave={() => setShowToolsMenu(false)}
          >
            <a href="#" className="hover:text-blue-600 active:text-blue-800 transition-all flex items-center py-2">
              Tools <i className="pi pi-chevron-down ml-1 text-gray-400" />
            </a>
            {showToolsMenu && <DropdownMenu items={toolsItems} width="300px" styles={{ top: '-5px' }} />}
          </div>

          <div
            className="relative"
            onMouseEnter={() => setShowApiMenu(true)}
            onMouseLeave={() => setShowApiMenu(false)}
          >
            <a href="#" className="hover:text-blue-600 active:text-blue-800 transition-all flex items-center py-2">
              API <i className="pi pi-chevron-down ml-1 text-gray-400" />
            </a>
            {showApiMenu && <DropdownMenu items={apiItems} width="300px" styles={{ top: '-5px' }} />}
          </div>
        </div>
        <div className="hidden md:flex space-x-4 items-center">

        <Link to="/login">
          <button className="hover:text-blue-600 active:text-blue-800 transition-all">Login</button>
        </Link>
        <Link to="/signup">
          <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all">Sign Up</button>
        </Link>
        </div>
        <button className="md:hidden text-gray-600 focus:outline-none hover:text-blue-600 active:text-blue-800 transition-all" onClick={toggleMobileMenu}>
          <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            {/* SVG for 3 straight lines */}
            <line x1="4" y1="7" x2="20" y2="7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="4" y1="12" x2="20" y2="12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            <line x1="4" y1="17" x2="20" y2="17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button>
      </nav>
      {/* Mobile menu */}
      <div id="mobileMenu" className={`fixed inset-0 bg-white z-50 p-8 ${mobileMenuVisible ? 'flex' : 'hidden'} flex-col space-y-4`}>
        <button className="self-end text-gray-600 hover:text-blue-600 active:text-blue-800 transition-all" onClick={toggleMobileMenu}>
          <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <div>
          <button className="flex items-center justify-between w-full text-left hover:text-blue-600 active:text-blue-800 transition-all" onClick={() => toggleMobileSubMenu('convert')}>
            Convert <i className={`pi pi-chevron-down ml-1 text-gray-400 ${mobileSubMenu === 'convert' ? 'rotate-180' : ''}`} />
          </button>
          <div className={`flex flex-col pl-4 transition-all overflow-hidden ${mobileSubMenu === 'convert' ? 'max-h-screen' : 'max-h-0'}`}>
            {convertItems.map((item) => (
              <a href={item.href} className="hover:text-blue-600 transition-all py-2 flex items-center" key={item.label}>
                <i className={`${item.icon} mr-2`} />{item.label}
              </a>
            ))}
          </div>
        </div>

        <div>
          <button className="flex items-center justify-between w-full text-left hover=text-blue-600 active=text-blue-800 transition-all" onClick={() => toggleMobileSubMenu('tools')}>
            Tools <i className={`pi pi-chevron-down ml-1 text-gray-400 ${mobileSubMenu === 'tools' ? 'rotate-180' : ''}`} />
          </button>
          <div className={`flex flex-col pl-4 transition-all overflow-hidden ${mobileSubMenu === 'tools' ? 'max-h-screen' : 'max-h-0'}`}>
            {toolsItems.map((item) => (
              <a href={item.href} className="hover-text-blue-600 transition-all py-2 flex items-center" key={item.label}>
                <i className={`${item.icon} mr-2`} />{item.label}
              </a>
            ))}
          </div>
        </div>

        <div>
          <button className="flex items-center justify-between w-full text-left hover=text-blue-600 active=text-blue-800 transition-all" onClick={() => toggleMobileSubMenu('api')}>
            API <i className={`pi pi-chevron-down ml-1 text-gray-400 ${mobileSubMenu === 'api' ? 'rotate-180' : ''}`} />
          </button>
          <div className={`flex flex-col pl-4 transition-all overflow-hidden ${mobileSubMenu === 'api' ? 'max-h-screen' : 'max-h-0'}`}>
            {apiItems.map((item) => (
              <a href={item.href} className="hover-text-blue-600 transition-all py-2 flex items-center" key={item.label}>
                {item.label}
              </a>
            ))}
          </div>
        </div>
        <Link to="/login">
          <button className="hover:text-blue-600 active:text-blue-800 transition-all">
            Login
          </button>
        </Link>
        <Link to="/signup">
          <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 active:bg-blue-800 transition-all">
            Sign Up
          </button>
        </Link>
      </div>
    </header>
  );
};

export default TopNav;
