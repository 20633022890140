import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import logo from '../assets/doctransit_logo.svg';
import '../styles/customStyles.css'; // Adjust path according to your file structure

const SignUp = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSignUp = () => {
        // Add signup logic here
    };

    return (
        <>
        <header className="flex justify-center p-4 bg-gray-50">
            <Link to="/">
                <img src={logo} alt="DocTransit Logo" width="140" />
            </Link>
        </header>

            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                    <h2 className="font-syne text-4xl font-bold mb-8">Sign Up</h2>
                    <div className="mb-4">
                        <label htmlFor="username" className="block text-gray-600 mb-2">Username</label>
                        <InputText
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-600 mb-2">Email</label>
                        <InputText
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-600 mb-2">Password</label>
                        <Password
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            feedback={false}
                            toggleMask
                            className="w-full"
                            inputClassName="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="confirm-password" className="block text-gray-600 mb-2">Confirm Password</label>
                        <Password
                            id="confirm-password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            feedback={false}
                            toggleMask
                            className="w-full"
                            inputClassName="w-full border border-gray-300 p-2 rounded"
                        />
                    </div>
                    <Button
                        label="Sign Up"
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-all"
                        onClick={handleSignUp}
                    />
                </div>
                <div className="mt-4 text-center">
                    <span>Already have an account? </span>
                    <Link to="/login" className="text-blue-600 hover:underline">Login</Link>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SignUp;
