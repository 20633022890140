import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactUs from './pages/ContactUs';
import AudioConverter from './pages/AudioConverter';
import Login from './pages/Login'; // Ensure this path is correct
import SignUp from './pages/SignUp'; // Ensure this path is correct
import Pricing from './pages/Pricing'; // Ensure this path is correct
import Api from './pages/Api'; // Ensure this path is correct
import Dashboard from './pages/Dashboard'; // Ensure this path is correct
import Jobs from './pages/Jobs'; // Import new component
import Billing from './pages/Billing'; // Import new component
import Account from './pages/Account'; // Import new component
import ApiKeys from './pages/ApiKeys'; // Import new component
import DesignSystem from './pages/DesignSystem'; // Import new component
import Oauth from './pages/Oauth'; // Import new component
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/audio" element={<AudioConverter />} />
                <Route path="/login" element={<Login />} /> {/* Use the correct component names */}
                <Route path="/signup" element={<SignUp />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/api" element={<Api />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/jobs" element={<Jobs />} /> {/* Add Job List route */}
                <Route path="/billing" element={<Billing />} /> {/* Add Billing route */}
                <Route path="/account" element={<Account />} /> {/* Add Account route */}
                <Route path="/api-keys" element={<ApiKeys />} /> {/* Add API Keys route */}
                <Route path="/oauth" element={<Oauth />} /> {/* Add Oauth route */}
                <Route path="/designsystem" element={<DesignSystem />} /> {/* Add Oauth route */}
                {/* Add other routes here */}
            </Routes>
        </Router>
    </React.StrictMode>
);

reportWebVitals();
