import React from 'react';
import logo from '../assets/doctransit_logo.svg';

const Footer = () => (
  <footer className="border-t border-gray-300 py-24">
    <div className="container px-4 md:px-8 mx-auto flex flex-col md:flex-row justify-between items-start md:items-center space-y-6 md:space-y-0">
      {/* Logo and Copyright Notice */}
      <div>
        <img src={logo} alt="DocTransit Logo" width="140" />
        <p className="text-sm text-gray-600 mt-6">&copy; 2024 DocTransit, Inc. All rights reserved.</p>
      </div>
      {/* Main links */}
      <div className="flex flex-wrap justify-end space-x-6 text-gray-600">
        <a href="/" className="hover:text-blue-600 active:text-blue-800 transition-all">Home</a>
        <a href="/privacy-policy" className="hover:text-blue-600 active:text-blue-800 transition-all">Privacy Policy</a>
        <a href="/terms" className="hover:text-blue-600 active:text-blue-800 transition-all">Terms of Service</a>
        <a href="/contact-us" className="hover:text-blue-600 active:text-blue-800 transition-all">Contact Us</a>
      </div>
    </div>
  </footer>
);

export default Footer;
