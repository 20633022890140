import React from 'react';
import 'primereact/resources/themes/nova/theme.css';
import 'primeicons/primeicons.css';
import 'tailwindcss/tailwind.css';
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';
import logo from '../assets/doctransit_logo.svg';

function TermsOfService() {
    return (
        <div className="font-sans bg-gray-50">
            {/* Navigation */}
            <TopNav />

            {/* Terms of Service */}
            <section className="container mx-auto py-20 max-w-[950px] px-4 md:px-8">
                <h2 className="font-syne text-5xl font-bold mb-10">Terms of Service</h2>
                <div>
                    <h3 className="text-2xl font-semibold mb-4">1. Introduction</h3>
                    <p className="text-gray-600 mb-6">
                        Welcome to DocTransit. By using our services, you agree to be bound by the following terms and conditions...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">2. Service Usage</h3>
                    <p className="text-gray-600 mb-6">
                        Your use of our services is subject to compliance with these terms. You agree to use our services for lawful purposes...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">3. Privacy</h3>
                    <p className="text-gray-600 mb-6">
                        We take your privacy seriously. Please read our Privacy Policy to understand how we handle your personal information...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">4. Liability</h3>
                    <p className="text-gray-600 mb-6">
                        We are not liable for any damages or losses arising from your use of our services, including but not limited to...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">5. Termination</h3>
                    <p className="text-gray-600 mb-6">
                        We reserve the right to terminate or suspend access to our services at any time for any reason, without notice...
                    </p>

                    <h3 className="text-2xl font-semibold mb-4">6. Changes to Terms</h3>
                    <p className="text-gray-600 mb-6">
                        We reserve the right to update or modify these terms at any time. Your continued use of our services after such changes...
                    </p>
                </div>
            </section>

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default TermsOfService;
